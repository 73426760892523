import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>
    <a name="simul"></a>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "435px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "79.14110429447852%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsSAAALEgHS3X78AAAECElEQVQ4y5WUXUxbZRjHjzPeaGIMak12oWaA0QvjhSYb0V1oNLtZlnjhR3Zj1BjZJnNLxoZxuIEmw1Hb0tANJiizDBTKZ8vHKFAdsPJVChRaKP3+gvLV79Jzes779+1hLl5445P887zP++b5nf97zvsehhd45KLJ8gsOq9+AYlZGcjXHcxlCSCHzfyMrZEWgfEaKp5VP4sr4ZRFII0MlAml+huo5Kgl9iCSXBSJIWD4tjnPKCpyEEzIShuVZsVs6XY28mqdwfuQc8cW9sGwtspG9SD5deoRCjDQnad6k2s6J1qIoWMzrqbVtb3xxm3ngBs3LjTjWfgSfD5wkrzS+jNd+fZXtsnceoksHaJMd/xEZkng4DqatcCVnwaiXbuPn+QYMedUYCsigc6vIFeMp/DBZwprD93MOD1BH1lxTgtvJ8gIn0KGQ4HaF2U2dQAjE2pdYEhzRGYE5on4dL9Y9j1pzOfq8Ugz568lIUIXhgIpdT68cyr1DCrTlgKbNPj7JRURHcXYH1l3DQ4f+xBIc0WkwbzYXoaC+ECrzVQx4ZbjrrSdamrUeOWvfMhUcP/rx43TLInB+a1DI8EkRwJMU2uxKrO2uibU3btkHVhi/Qulfn0DjkkMXrII+VE/6vAr0exWZGfvoS5K8gwVcllvNNRnXe4RYNoAR6w6MzjAqpj5Fi7VVBLpjC/tAQ7AOwyElxpfVGNDXwDDfRHoCcvR4FGxgzyZumZ5V0WGLTc4r56+ieWITrdMOyObPoGOl84HDRdgjU2B6nTUYDFxDWXsvXijux7nftMQckmLMqWA34pb8mD33Ufa3fMcm4+Vz5Wif2kaHyYXquWL0rHVBa0rB6J/Cxp4FjNanhD5chRKNFk+U9OP9Gj25fEODiyoNO7XsFo9NiuVEYJtdwVcYv0Gl1o2uOTeum4rR7+rGtd4QRp2TMARawdy13MS9VRm+btEh76wWb1WNEMmFARwsHWBv3dsHTru3bQ3jm9B5avlvx8pwUeMQgfL507gx24ILf7gx4zfjS/0JMEXf9WYLz7dly7sXslLDWrZqaIU7UTsmfFA/ke42B0Tgn6sbVoUhjH6/igIvkbJOJ+la8BHFwmlSbVSTU80uMrtuIWcNHxKmVOfAF202dK9EMOJLYCKYgsGfgt4VwWo4Vpi7ej8O26zSjmXolq9nqUNySWMXNL8PEtn9zyCdvIMztxYwaWgjJaMfCUwylWyKJ6K343tsQyiaqktwuLkRTTV4w7uNu4nUs0D6Md9OfC0cjmIj7EQosY5QJI2g04NwzMNvpaN8YDMhbPnc8Mc8+PePR0J1mOooVf4/k45w9FHqsojqGNW7VO9RvT1tNp2US5WV8uqfvlcqpJXb8dRxOv/O3xePuc5O6dy8AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "stackedvsgrouped",
          "title": "stackedvsgrouped",
          "src": "/static/685111a5ff8509988d3d78989f367a6e/330eb/stackedvsgrouped.png",
          "srcSet": ["/static/685111a5ff8509988d3d78989f367a6e/222b7/stackedvsgrouped.png 163w", "/static/685111a5ff8509988d3d78989f367a6e/ff46a/stackedvsgrouped.png 325w", "/static/685111a5ff8509988d3d78989f367a6e/330eb/stackedvsgrouped.png 435w"],
          "sizes": "(max-width: 435px) 100vw, 435px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <iframe width="100%" height="800" frameBorder="0" src="https://observablehq.com/embed/@alainro/stacked-grouped-percent-or-smallmultiple?cells=viewof+ctr%2Cchart"></iframe>
    <p><em parentName="p">{`Cliquer sur l'étiquette du classeur pour y accéder dans Observable`}</em></p>
    <br />
    <h2>{`Les empilées`}</h2>
    <blockquote>
      <p parentName="blockquote">{`La série le plus en bas `}<strong parentName="p">{`et`}</strong>{` la somme de toutes les séries sont bien lisibles. Mais toutes les autres sont rarement interprétables.`}</p>
    </blockquote>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "47.852760736196316%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABpElEQVQoz62QTUsbURSG54/0B3Tbtatu+wtcdlECrkor7gSFFhfVhYvSjaExftQ2iVjBsdH5UqkaM5mGlKaUSUKMNgYyNYI2pWbuPJ2ZJFSwLgoeOBwul/d5z3ukjisIKl2sMrr6Ec/zwnd//m9JrugC899PSRSqtwe0nQtShfrtAFPmEXMHh6x9beCKv8B+XzUIpvA1NxlLwQ3vjW/wMJ5lq+Jw2bvp1bpJ/K8UIfD+C52hNxZGuekD3fCjI1zOfp2HM9D1T1NrNdivfb5mIjzhtxdEdhmY0Igs5DAqTbxO16nV/s28aeP8bPe8u0Z2K0PKniLQiV78vlm4oQ/mwbTOk8QnlFKTpP2N+nnb3+SEiDxG+UcDz2c1LwSZ4xpydR35+PW1qBXnhLJTRxp+v0ksZxGziixZNkYiSlz9wIg2y93oHQaXHxNZfcaj5FOU2HPM2UnyyzPslSwytYof/xCtlGe+OINytIKU3HnHbm6L3axBLrsNG5tkjUUWdl6R1F8S1ydZ0aYxlSgdTQFV51JTOVAT6PsyaTONvP0WNSPzpVzgD6Ju38EUWKvyAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "stack",
          "title": "stack",
          "src": "/static/07e16af400b00ebc7ce37b36446b2ff5/a6d36/stack.png",
          "srcSet": ["/static/07e16af400b00ebc7ce37b36446b2ff5/222b7/stack.png 163w", "/static/07e16af400b00ebc7ce37b36446b2ff5/ff46a/stack.png 325w", "/static/07e16af400b00ebc7ce37b36446b2ff5/a6d36/stack.png 650w", "/static/07e16af400b00ebc7ce37b36446b2ff5/e548f/stack.png 975w", "/static/07e16af400b00ebc7ce37b36446b2ff5/3c492/stack.png 1300w", "/static/07e16af400b00ebc7ce37b36446b2ff5/277c6/stack.png 2522w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`Si la somme des séries en dessous est à peu près stable, alors l'oeil arrive à voir l'évolution individuelle de la série. Sinon c'est juste impossible. Quelle est l'évolution du bleu pale et du bleu foncé après leurs pics en 1 et 2 ? `}</p>
    <br />
    <p>{`Il existe pourtant quelques situations où les barres empilées présentent une réelle plus value. Je pense au cas où les séries sont `}<strong parentName="p">{`dépendantes les unes des autres`}</strong>{`. Quand la décroissance de l'une s'explique par la croissance d'autres. Je connais plusieurs bons exemples de cette situation, qui justifient pleinement l'usage des barres empilées.`}</p>
    <br />
    <p>{`Il y a l'empilement du nombre de travaux dans chacune des catégories : A faire, En cours, En test, En validation, Terminé. Comme dans le schéma suivant. `}</p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.576687116564415%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAACLklEQVQoz2NgYGDQ1dPV1tFiIA9wcXGysbEJCAiIiory8vKKiYmJiIhwc3PLysqKCIsIC4sAGZzcXBzc3CysXNiNEBETNzQ0MjM1tbS0tLa21tXV0dTUMDY1kpaXNDE2DvGN8HZ0drHR5uZmR9fJycQYJMLNz8wEZDNChBgZmRlZVMTUIx2L663C1wW7HF8ZP3l5q7lHHFCSiYkZSTMzU4m+hhA3N5DNw8VrqGwRq2naHz1pTdXFPYkd52N8d/XYt2yeFth4Wdc5E2QysmZ2JqYMVRleNg4Xw+CehEXLSo8v8Y5cGDvjeFLrVn/NI+UGjYmh/k0HoxoO6zjEIzRDHMnFzFCgrlXv17AgZ+v8nG1zMtYtco07ERXxbmrwmii9xtqU2JyFgZU7wuv3a9pEIzQzgTU7i0tNNbOfahswwcZ7vlvgMleXHc6628K1l4ap5sWXBNQdCKzbF1C6MaRmj5plKJJmRpD2KMu4SQkLeiJ6NgUmXo13eT3d7/Ku0EkbEuJrJviX7Aio2BJQst63aG1QxVYV0wB0zYFmMTN8i9a7eRx0N7sxzXvv8eSGtcUx7Qv8SrcHlG3wL10PRL5FqwMrtigZ+yI0MzOCfB2jpbs+0Gh5nlVPslVBiXdka4V76hTnpFmuKVNdkkHIOXmqY+Ik17RZMlr24HhkQoQ2HweztBiXuLSwmKKYgISUoJCCkLC4qLiksKC4oLCUkIi0kKg0B48gO7cgMys7A1UAAHUAmVWFa6D4AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "flow",
          "title": "flow",
          "src": "/static/4ab4aa23b047ba37654b936541b6af6e/a6d36/flow.png",
          "srcSet": ["/static/4ab4aa23b047ba37654b936541b6af6e/222b7/flow.png 163w", "/static/4ab4aa23b047ba37654b936541b6af6e/ff46a/flow.png 325w", "/static/4ab4aa23b047ba37654b936541b6af6e/a6d36/flow.png 650w", "/static/4ab4aa23b047ba37654b936541b6af6e/e548f/flow.png 975w", "/static/4ab4aa23b047ba37654b936541b6af6e/21b4d/flow.png 1280w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`Les données ne sont pas indépendantes, les transitions d'un item d'une catégorie à une autre (A faire -> En cours) fait juste bouger la frontière sans changer le volume global. L'intérêt de la représentation est qu'elle permet de voir des quantités combinées :`}</p>
    <ul>
      <li parentName="ul">{`Travail restant à faire (Remaining to be done)`}</li>
      <li parentName="ul">{`Temps de développement (Cycle Time)`}</li>
      <li parentName="ul">{`Travaux en cours (Work in Progress)`}</li>
      <li parentName="ul">{`Travaux en attente (Backlog)`}</li>
      <li parentName="ul">{`...`}</li>
    </ul>
    <br />
    <p>{`Il existe aussi une belle version adaptée à la résolution de problème d'une hot-line sur des catégories : Ouvert, En investigation, Reproduit, Répondu, Fermé. `}</p>
    <h2>{`Les empilées en pourcentage`}</h2>
    <p>{`La version pourcentage des barres empilées souffre du même défaut : évolutions des séries individuelles pas lisibles. Elle est pourtant elle aussi tout à fait recommandable quand les séries sont dépendantes entre elles. `}</p>
    <br />
    <blockquote>
      <p parentName="blockquote">{`Aussi mauvais que les barres empilées simples mais capable de masquer à dessein la grandeur globale (sommes des séries) pour ne montrer que les contributions relatives.`}</p>
    </blockquote>
    <br />
    <p>{`Voici un très bon exemple :`}</p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "88.34355828220859%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAABcSAAAXEgFnn9JSAAADaklEQVQ4y2WUN0gtXxDGt1AUMzaiIIqCjSBqY21rYyMKIhbaqq0oiI0gqJhjoahgY845Y8Scs+IqmONdmwd3v//55s/ed997C8Ps2T37OzPfzI728PBgXF1dIScnx0xISEBSUhJSUlKQnJwsPisrCw0NDTg4OMDd3R1eXl5weXmJ7e1tbGxsYGVlRezw8FDea+/v78bj4yMKCgpMQry9vaFp2j8WExOD1tZWjI6OYm5uDkdHRzg+Psb19TX29/exvr6Oi4sLaG9vb8bT0xPm5+fNyclJ1NbWIjg4GG5ubvD19YWfn5/cExodHY3IyEjk5uaCWa2urqKvrw+bm5tYWFjA/f09NJWCodLGzMyM2d3djdnZWeTn5yMqKgoBAQEICwuDh4cHXFxc4OrqKuCMjAy0t7fL4WVlZWhra8PNzQ2Yqfb19WU8Pz9jamrK7OzsBG1kZEQ8NSXAy8tLoo6LixNobGwsGhsbUVVVJdDy8nKBnp2d/Q9kykoXc3h4WGD0KmL09vbC09NTChMYGChwHx8fAVZUVAispqYGdXV1Am1ubv6dstLAnJ6eFl2WlpZEE2paUlKCjo4OxMfHCzAiIkLkyMvLQ1NTEyorKyVSgnnvqDKLQoFZRUZIGxwcxNjYmDzjB6GhoSIDC5OYmChpLi4uYmtrS6Krrq6G9vn5KRoywoGBAdDGx8cFNDQ0JFDKoDRGaWmppJmamoqQkBABshfPz8+lSExbImTKqjlN6kazYAT39PSgq6tLjGD2YHFxsWjJA+rr6yV6q0AO4NrammjItiGUnh9bhzByRsTUWlpaEBQUhMzMTFkTxnQJ1r6/v43X11cWw6QeCozl5WVq6oBaNjExIZH39/cjPT1dGp1ARskCEawpmERIICtLIP9NZyC9dc89jJgShIeHS5OzE7KzsyVy7efnxwFkylaKf0dnmQVnNoWFhfD394e7u7ujpQTIKrMorKSlobM5H+S8puckKioqkr5MS0uDZhiGjWPn5OTETlNj6g+vpor99PRU1mq62NWYcqz5Xk0Y++3trV2NNLuu63bNNM1f6gLTVtGCBVKFkrmnfkt8fHyI53ObzQbVFfLe2sc19/Dn4Pcsytnu7u69ikRXc02nV3AxtUm82qOrLPSdnR1dRSj76Pf29uSZam4xNb70/wDaHB3D4pU7OAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Energie",
          "title": "Energie",
          "src": "/static/c8ce854997d0d868a53848f8a8c2d493/a6d36/Energie.png",
          "srcSet": ["/static/c8ce854997d0d868a53848f8a8c2d493/222b7/Energie.png 163w", "/static/c8ce854997d0d868a53848f8a8c2d493/ff46a/Energie.png 325w", "/static/c8ce854997d0d868a53848f8a8c2d493/a6d36/Energie.png 650w", "/static/c8ce854997d0d868a53848f8a8c2d493/e548f/Energie.png 975w", "/static/c8ce854997d0d868a53848f8a8c2d493/4cdf7/Energie.png 995w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`L'intérêt de cette visualisation est de montrer la tendance générale de `}<strong parentName="p">{`substitution relative`}</strong>{` des énergies primitives (muscle et feu de bois), par les énergies fossiles au travers des siècles indépendamment de l'augmentation exponentielle du montant absolu des énergies. Comme quoi il n'est pas possible de condamner complètement un type de diagramme pour mauvaise visibilité, cela dépend des données et des objectifs.`}</p>
    <h2>{`Les groupées`}</h2>
    <p>{`Si on vient de parler barres empilées, les barres groupées deviennent le moyen de voir les `}<strong parentName="p">{`contributions individuelles`}</strong>{` de chaque série. Une façon de combler le talon d'Achille des empilées ? Oui pourquoi pas, mais pas pour beaucoup de séries.`}</p>
    <br />
    <blockquote>
      <p parentName="blockquote">{`Passé 4 séries, les barres groupées deviennent illisibles. Et si le nombre de points dépasse 30, impraticables ! `}</p>
    </blockquote>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "49.079754601226995%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABM0lEQVQoz62RWUvDQBSF++P1l4igryL64oKIBgttxNimTWkbkjRNE7O2iZnJcpxJMBHSh6IeuAyXO3x3OT1KKbjKsqyCK6E5wk+KvCib2qHqEdIFxiSDF5PfAX9OyFWwl8OChP4fcLNN4TJolGZ/B+7YuoafVEA/6Z7jYCBXmlEIcxei7iFgpgQJ3TthnfMmbbPvYKaQGpZvYccmHpQ1pFVUuWyz1RtA2Z20ztuG/GmAITGhBTrOxD4e51oF1Py4gRRl0YCKKt93AjZhxtbcUQI1muBGmeLo/gTHt5fQg5CZw51uv5MiRZLFTe6wupdaUBwTL5qM0WaGnh06GK9VvJsyrkdvOB1c4Xz4BGEpYWbZLAwsHQeGa+HVmEI0JOjuBzR3hf5CxfNigDt5zE41xIUk4AuawgetMxnIogAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "grouped",
          "title": "grouped",
          "src": "/static/e93496897b31d3138b1fc600c8a9ab45/a6d36/grouped.png",
          "srcSet": ["/static/e93496897b31d3138b1fc600c8a9ab45/222b7/grouped.png 163w", "/static/e93496897b31d3138b1fc600c8a9ab45/ff46a/grouped.png 325w", "/static/e93496897b31d3138b1fc600c8a9ab45/a6d36/grouped.png 650w", "/static/e93496897b31d3138b1fc600c8a9ab45/e548f/grouped.png 975w", "/static/e93496897b31d3138b1fc600c8a9ab45/1628f/grouped.png 1232w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Essayez de simuler 4 séries et 10-40 points dans le simulateur en tête de ce billet.`}</p>
    <br />
    <p>{`Il y aussi des utilisations des barres groupées qui ne sont pas dans le contexte de cumul mais plutôt de `}<strong parentName="p">{`comparaison`}</strong>{`.`}</p>
    <p>{`Les barres groupées de `}<strong parentName="p">{`comparaison`}</strong>{` m'inspirent de la méfiance au premier abord. Dans sa version la plus élémentaire il s'agit de montrer la différences entre deux séries.`}</p>
    <p>{`Comme par exemple :`}</p>
    <ul>
      <li parentName="ul">{`budget mensuel / dépenses mensuelles`}</li>
      <li parentName="ul">{`mois de l'année N-1 / mois identique de l'année N`}</li>
      <li parentName="ul">{`concurrent / notre produit`}</li>
      <li parentName="ul">{`...`}</li>
    </ul>
    <br />
    <p>{`Mais pourquoi ne pas plutôt visualiser `}<strong parentName="p">{`une seule série`}</strong>{` constituée de la différence ou alors une solution qui superpose un cadre (budget) et un contenu (dépenses).`}</p>
    <br />
    <div className="row">
  <div className="column">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "650px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "65.6441717791411%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAAAFklEQVQ4y2P4T2XAMGrgqIGjBg4LAwEOEgwtl6nP+QAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Blank",
              "title": "Blank",
              "src": "/static/638fa950451a94b10ef7204cc44c49be/a6d36/Blank.png",
              "srcSet": ["/static/638fa950451a94b10ef7204cc44c49be/222b7/Blank.png 163w", "/static/638fa950451a94b10ef7204cc44c49be/ff46a/Blank.png 325w", "/static/638fa950451a94b10ef7204cc44c49be/a6d36/Blank.png 650w", "/static/638fa950451a94b10ef7204cc44c49be/68e9c/Blank.png 654w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
  </div>  
  <div className="column">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "650px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "78.52760736196319%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAABYlAAAWJQFJUiTwAAACuElEQVQ4y6WTX0hTURzH70tERfVQERT0VBSR9lcrXEZWL0FkRdBD0VPQQyjrISKC3qwwQuohEQ2SGGW1Zf98KFAh01lDQ3Tu792MzW33z7a7dZfbvft2zrnbnJoQdODsd3f+fM7394+LxmIIhcJQVRV05PN5lA/6X9eNtf7xEG5Z7LhnG2HW4RPYOt0v3uMkSYYgiEilUosCc5rOvts/TaDS/AJHb78ltgtv7Dxb13R9FhiJRJlCRVkcSC/Q0dnnQs1NG07d6YGJ2A+O4EJgPJ5gCtPpdAlQnPOBT3sncfCGFSebPjL7/ntgIVAUJVCViqIY8fhfIE1KODxdiuF8dxlQ+0cgmZwsx6Ek4hj1hNDYMQDzkwFcae1H++cJQ3E2B43Mv8awCMxq5EdDnjzMXJbFGHpHfNh17SWqrr/C1qsWAv7CDlNUtqC4jWR5R+Nz1JEsVxBrGzaynKHQwhkuRZIhxKIYcwfx6J0DLdYhPLDa0T3ghPisG/zdNvDNHRAeW9A35ESzdRgtrwfRbPsG++A4hIed8N/vAN/UikhXD7hEMgkax4z6qxC4XCmGfOUZuLhtcC/ZCffyfYCUMDZyM8ZRF49Jbjs8y/YSuxk/j18GNx2JsDpMJhXQPNEEaKTyadymai/Bs/oAvOtr4dt0DNmoxFyjezRNGacfnjU18G6sg3tFFUJnzbRTJAhiWR0WSiRPghysucAOetea4N1wBLmYVHDCiNgMBa7azx50L92NUH0DOEEQ5tRhvtC3FDhlugj3ymp41x2Cj6hYHHjYAJ5uMFqP1mGp9YpAcilYfR4uEj8PhRKVuag0xwtdzUAd+oHM8BjUr6PsAS6RSEKWZVLY6Tm9TBVSF/xbToCvqEdgzzloYrz06PyeLw7O5/OD5wOzLpe1nJ75zVQUZ/leqZNIEo2pMeV/AG2ZXHg/pkkNAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "negativebars",
              "title": "negativebars",
              "src": "/static/c40e790a578ba093b3ae5d5a7bbb6fe3/a6d36/negativebars.png",
              "srcSet": ["/static/c40e790a578ba093b3ae5d5a7bbb6fe3/222b7/negativebars.png 163w", "/static/c40e790a578ba093b3ae5d5a7bbb6fe3/ff46a/negativebars.png 325w", "/static/c40e790a578ba093b3ae5d5a7bbb6fe3/a6d36/negativebars.png 650w", "/static/c40e790a578ba093b3ae5d5a7bbb6fe3/f6f78/negativebars.png 932w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
  </div>
  <div className="column">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "650px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "65.6441717791411%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAABl0lEQVQ4y5WTfU+DMBjE/f7fyn+ML3Nuujl1iRqjE7fRV2ClA85rBxrU6CRpSgv8enfPwwF4NU2Duq57c7e/7+jePwg3ZVlikbzhNVkiWa5gbIb/Xh00Av3WQ0iFrffY8t6XYd7+OpxzKDYOWVHEdU+hJ0gqDZk55M7Hh8F6HDGG5nOudxZzY7FWBsk6JbzsA4MyXTgsR1Po+ye4+4cPC+jm/1iOQMpXgxHMzRzLwyNUxqChnQ7aK8BfRQm5ibyAPB/D3M4hzi5gpzPYyTXqaP9nyNdu+FBYMVTLQsjznUJxOoS6mkGNp0ieEuQ2R/OL1W8KAzBYjgpboJ4QeDmFOSOcuaaPz/AsXGUtakax8RVMWQVMC91F0wI90iyHaoGyBWoqNKMrqOs72MEFxGgCSeXp8QDrxQrPL6udsk5pZ7lhezjmlLIoisA1geFDSaAajiFmcyiCBdeCB61Ohjz4DpLPFjLnj7D5tNz5D32mU4mCSo1QyNhnodcsbeb8czJtOCz3M+6Z+J7lnpYajnH1Mmz27LV9+vAdhtHvooYkzzMAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Jauges",
              "title": "Jauges",
              "src": "/static/219bd90b0b0b98825b3da1a3312868ed/a6d36/Jauges.png",
              "srcSet": ["/static/219bd90b0b0b98825b3da1a3312868ed/222b7/Jauges.png 163w", "/static/219bd90b0b0b98825b3da1a3312868ed/ff46a/Jauges.png 325w", "/static/219bd90b0b0b98825b3da1a3312868ed/a6d36/Jauges.png 650w", "/static/219bd90b0b0b98825b3da1a3312868ed/5a190/Jauges.png 800w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
  </div>  
    </div>
    <br />
    <p>{`C'est bien plus simple et efficace pour la lecture. Bien sûr cela devient plus compliqué pour 3 ou 4 séries, mais je suis certain qu'il y a de meilleures solutions que les barres groupées.`}</p>
    <h2>{`Les petits multiples`}</h2>
    <p>{`L'expression `}<strong parentName="p">{`petits multiples`}</strong>{` (small multiples en anglais) désigne l'idée de remplacer un diagramme confus contenant plein de séries par `}<strong parentName="p">{`plusieurs`}</strong>{` diagrammes représentant chacun une série. L'évolution individuelle de chaque série devient entièrement visible. C'est à l'observateur de déplacer son oeil d'un diagramme à l'autre pour effectuer la comparaison entre les séries. `}</p>
    <br />
    <p>{`Il en existe plusieurs déclinaisons selon la nature des données et les aspects à mettre en avant, notamment :`}</p>
    <ul>
      <li parentName="ul">{`alignés horizontalement avec la même échelle des Y pour faciliter la comparaison des valeurs
`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.263803680981596%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAABeElEQVQoz2VSTUsCURSdH9e/aVHUqlVBq+gDCmsRVBQuahNBhNki/ICChCajHM2PclQ0ddT50Jlx5nl6902K1oFhLufNOefe+0ZijMFxHBB834emaSBuNBqJh2qCrutwXVfU43OCbdsYDAYTXqKCiLEBYVxTwLT5dIjvM2S+WvB8NqOR2u226NDj4r9CMpz+eNrQHXo4upGF8YyhqqrwPG9mFCESb4Zm18LQI+P/QVvnj7h9KiBdaEyCpGqlAttxUappM6bj5LNoGuVGb3LGWCCkiZb373BwlUKUmxKIkyzLRKujY/U4jrdScyL0WbDPTd5F6DL1e2mBISGZLmNuKYy1kzhWDu+RrwYNiUtJygXMb19j/TSO746JETek29MNExvhBHYuHqA2uiLItvm+XQex5wIWdiNYDkWxuBfh2hgSchESjefwkQ3DgKIoeM8oyGYV8fsYpgXT6iP3kYf88ir4er2Ofr/P1+SgVqsKLpfNolj6RFPr4QfYzFhviWBkMQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "smallMultiplesX",
            "title": "smallMultiplesX",
            "src": "/static/d5d6515b7f9475da569b4ce646e071e5/a6d36/smallMultiplesX.png",
            "srcSet": ["/static/d5d6515b7f9475da569b4ce646e071e5/222b7/smallMultiplesX.png 163w", "/static/d5d6515b7f9475da569b4ce646e071e5/ff46a/smallMultiplesX.png 325w", "/static/d5d6515b7f9475da569b4ce646e071e5/a6d36/smallMultiplesX.png 650w", "/static/d5d6515b7f9475da569b4ce646e071e5/e548f/smallMultiplesX.png 975w", "/static/d5d6515b7f9475da569b4ce646e071e5/3c492/smallMultiplesX.png 1300w", "/static/d5d6515b7f9475da569b4ce646e071e5/27f8b/smallMultiplesX.png 1730w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></li>
      <li parentName="ul">{`alignés verticalement pour faciliter la détection de corrélation de tendance (sans forcement la même échelle Y)
`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.901840490797547%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAzklEQVQY04WQzW7CQAyE8/5vxAWFihulFw6cgEuQqmy2UZuym02y/rBBldpCiyVLln9mPFO4pkEkk/OdFCFPE7nvkbZl2u9x6xdenaO3ns11z8L7N6rjkSLGeGnY8CatP46IHe92sNmQq+pC9P3mq26VtPjouv8BdS7eIwrE84phu6UL8ceeRUrpChhj/zeg5TAgdY0cDrBcMpYl76dwA2hKG7XvseQQrh8qO4uS9LTgUy3gLqCn6B5JNv/UbFGpzGak+ZyTfv0bMChxXTvOoTTShQJtSPYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "smallMultiplesY",
            "title": "smallMultiplesY",
            "src": "/static/ab66d97200fff72e9b91752aba3b41ca/a6d36/smallMultiplesY.png",
            "srcSet": ["/static/ab66d97200fff72e9b91752aba3b41ca/222b7/smallMultiplesY.png 163w", "/static/ab66d97200fff72e9b91752aba3b41ca/ff46a/smallMultiplesY.png 325w", "/static/ab66d97200fff72e9b91752aba3b41ca/a6d36/smallMultiplesY.png 650w", "/static/ab66d97200fff72e9b91752aba3b41ca/e548f/smallMultiplesY.png 975w", "/static/ab66d97200fff72e9b91752aba3b41ca/3c492/smallMultiplesY.png 1300w", "/static/ab66d97200fff72e9b91752aba3b41ca/cc40b/smallMultiplesY.png 2394w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></li>
      <li parentName="ul">{`en treillis avec la même échelle Y et rappelant pourquoi pas dans chacun toutes les autres séries sur forme grisée pour expliciter la position de la série courante au milieu des autres
`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.39263803680981%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABcSAAAXEgFnn9JSAAAB8ElEQVQ4y31TibKaQBDk/z8vVYlBPFBReQ9B5Fx2YTvTi+szmpetmmKPObp7hiCO42m1WmGxWNgoirBerxGGIc7ns7PsckGWZTidjkiSA6qqApe1Fn4974M0PU9JkqBtG9v3PZTqZd/if4sJvrOgV2pSSjm/aZpQFAWu1+ubI99oPmGe56ib5q1Y0HbdFG4OaNrO4WbQOI6PJH5PU2p4JPVfI+9126HtencX8I2X693RplnugiQaozEYBoU7ek8WxmicPi6IkxRJmmF3TLE9nLDZH1HVNQKpPFG78naz4XoH2u/V/P2UAuWthtaSXGtkeYFfyy2izR6FyML3a1lCS+FxNLOGTKiGQSob6/VRgwbviGSXfFAO0asVFifc6uav5pAmWQ3i7zQkZf+uBQUp0rxG33WZCajv671DeA+2HBd2+SKzRxme12uXOY9d17132flKJXG2/0JDqtWdJpG/ouLinUg2I5TDVFYNblVtSWMOMjxjLx0MpQFt+4VE61kvdpvF6MefQXsNJcG02R2ls7H9sYj4xc/lxnUz/byAus7oBqziBIto64xTsJRi0Xbv7rcyRo0M+r0pbnCtGWedejV4lR8JS0GS5aWjN4qPMV/U6e+H3lEmfy1jQ6rcM4iJtNDi+dFxa9352ejnitz9/gDx8z9l/+ZQawAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Medecine",
            "title": "Medecine",
            "src": "/static/06af13d391f736ec242959eb62b4d128/a6d36/Medecine.png",
            "srcSet": ["/static/06af13d391f736ec242959eb62b4d128/222b7/Medecine.png 163w", "/static/06af13d391f736ec242959eb62b4d128/ff46a/Medecine.png 325w", "/static/06af13d391f736ec242959eb62b4d128/a6d36/Medecine.png 650w", "/static/06af13d391f736ec242959eb62b4d128/e548f/Medecine.png 975w", "/static/06af13d391f736ec242959eb62b4d128/bb051/Medecine.png 1212w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></li>
    </ul>
    <br />
    <p>{`Il en existe un autre type que j'ai découvert récemment dans le `}<a parentName="p" {...{
        "href": "https://observablehq.com/@mkfreeman/separated-bar-chart"
      }}>{`classeur`}</a>{` de Michael Freeman : `}<strong parentName="p">{`Les petits multiples empilés`}</strong>{`.`}</p>
    <br />
    <p>{`L'originalité est de présenter les petits multiples verticalement sur un axe Y commun, permettant de jauger du `}<strong parentName="p">{`cumul des séries`}</strong>{`. Une sorte de barres empilées mais qui gardent la visibilité des évolutions individuelles. L'axe X de chaque série commence au maximum de la série précédente. Une manière de répondre à la question : « Et si toutes les séries en dessous étaient à leur maximum jusqu’où peut aller le total en ajoutant la contribution de la série actuelle ? ». Les séries sont triées par ordre d’amplitude de bas en haut.`}</p>
    <br />
    <p>{`Tellement content de découvrir `}<strong parentName="p">{`cette bonne idée`}</strong>{` que j'ai codé un simulateur sur `}<a parentName="p" {...{
        "href": "https://observablehq.com"
      }}>{`Observable`}</a>{`.`}</p>
    <br />
    <p>{`N'hésitez pas à jouer avec pour apprécier les domaines d'utilisation des différents types de représentations (faire varier nombre de séries et de points). `}</p>
    <br />
    <p>{`Pour moi, le `}<strong parentName="p">{`"petits multiples"`}</strong>{` est le grand gagnant dans la majorité des situations. Qu'en pensez vous ? `}</p>
    <br />
    <a href="#simul"><b>Voir le simulateur en haut de ce billet</b></a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      